import React from "react"

import SEO from "../components/seo"
import rond_color from "../assets/rond_color.svg"
import { Link } from "gatsby"

import Navigation from "../components/Navigation"


const lefuturdelamode = () => (
    <div
        className="containerManifesto"
      >
        <div className=" container containerHome">
        <Navigation/>
        {/*<div className="rondTache row">
          <img src={rond} className="col-3 rond"/>
          <img src={tache} className="col-9 tache"/>
        </div>*/}
    <div>
    <SEO title="Plaiz - Le Futur de La Mode - Plaiz, le réseau social de la mode"/>
    <div>
    <h1 className="p1">Le Futur de La Mode</h1>

    <h4
      style={styles.backToHomeLink}
    >
    <p className="textChangeGame"><br/>
    <br/>
            <b><i></i></b>
            <br/>
            <i></i><br/>
            <br/>
            <br/>
            Plaiz est une startup française qui mène de nombreux projets afin de transformer le futur de la mode<br/>
            <br/>
            <br/>
            <br/>
            <Link to={"/reseau-social"} className="textChangeGame">Le réseau social Plaiz</Link><br/>
            <br/>
            <Link to={"/pafw"} className="textChangeGame">Les évènements Plaiz</Link><br/>
            <br/>
            <Link to={"/collabs-surprises"} className="textChangeGame">Les collabs Plaiz</Link><br/>
            <br/>
            <Link to={"/plaiz100"} className="textChangeGame">Les Plaiz 100</Link><br/>
            <br/>
            <br/>
            <br/>
            Ils parlent de nous:<br/><br/>
            <a target="#" href="https://inouwi.com/partenaires/" className="textChangeGame"><b>- Inouwi</b></a><br/>
            <br/>
            <a target="#" href="https://cocy.fr/2017/10/09/plaiz-lapplication/" className="textChangeGame"><b>- Cocy : Plaiz l'application qui mêle street culture, mode et communauté</b></a><br/>
            <br/>
            <br/>
            <br/>
        </p>
    </h4>
    </div>
    </div>
    </div>
    </div>
)

export default lefuturdelamode

const styles = {
  backToHomeLink: {
    color: "white",
  },
}